import React from "react";
import { Auth } from 'aws-amplify';
import { TextButton } from '../Button';
import { SpinnerWhite } from '../Icons';
import { AuthState } from '@aws-amplify/ui-components';
import validator from 'validator';

// https://local.mxmcloud.com:8080/?code=966092&email=twarlick@gmail.com#reset-password
export default class ResetPassword extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      codeSent: false,
      loading: false,
      email: this.emailStatic?.toLowerCase() || '',
      code: this.codeStatic || '',
      password: '',
      confirmPassword: ''
    };

    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.sendCode = this.sendCode.bind(this);
    this.overrideCode = this.overrideCode.bind(this);
    this.backToCode = this.backToCode.bind(this);
    this.handleBackToLogin = this.handleBackToLogin.bind(this);
  }

  static get queryParams () {
    if (!this._queryParams) {
      this._queryParams = new URLSearchParams(window.location.search);
    }

    return this._queryParams;
  }

  static get codeStatic () {
    return this.queryParams.get('code');
  }

  static get emailStatic () {
    return this.queryParams.get('email')?.toLowerCase();
  }

  get codeStatic () {
    return ResetPassword.codeStatic;
  }

  get emailStatic () {
    if (this.props.user && this.props.user.email) {
      return this.props.user.email.toLowerCase();
    }

    return ResetPassword.emailStatic;
  }

  handleEmailChange (ev) {
    this.setState({ email: ev.target.value });
  }

  handleCodeChange (ev) {
    this.setState({ code: ev.target.value });
  }

  handlePasswordChange (ev) {
    this.setState({ password: ev.target.value });
  }

  handleConfirmPasswordChange (ev) {
    this.setState({ confirmPassword: ev.target.value });
  }

  handleResetPassword (ev) {
    ev.preventDefault();

    if (!this.state.codeSent) {
      this.sendCode();

      return;
    }

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        resetFailed: 'Passwords must be the same'
      });

      return;
    }

    this.setState({ loading: true });

    this.resetPassword().then(() => {
      console.info('Password reset');
    }, (err) => {
      console.error(`Error resetting password in: ${err.stack ? err.stack : JSON.stringify(err)}`);
      this.setState({ loading: false });
    });
  }

  get requiredAttributes () {
    return {};
  }

  async resetPassword () {
    var user, nextAuthState;

    try {
      user = await Auth.forgotPasswordSubmit(this.state.email?.toLowerCase(), this.state.code,
        this.state.password);

      if (!user) {
        nextAuthState = [ AuthState.SignIn, null, { from: AuthState.ForgotPassword } ];
      } else {
        console.debug(user);

        if (user.challengeName) {
          throw new Error(`Challenge not supported! ${user.challengeName}`);
        }

        nextAuthState = [ AuthState.SignedIn ];
      }
    } catch (e) {
      console.error(`Error setting new password: ${e.stack ? e.stack : JSON.stringify(e)}`);

      if (e.code === 'InvalidParameterException') {
        return this.setState({
          loading: false,
          resetFailed: 'Password must be at least 8 characters and include letters and numbers'
        });
      }

      if (e.code === 'ExpiredCodeException') {
        return this.setState({
          loading: false,
          resetFailed: 'Reset code expired.  Contact your administrator.'
        });
      }

      if (e.name === 'AuthError') {
        return this.setState({
          loading: false,
          resetFailed: e.message
        });
      }

      throw e;
    }

    if (nextAuthState) {
      this.props.handleAuthStateChange(...nextAuthState);
    }
  }

  handleBackToLogin (ev) {
    ev.preventDefault();
    this.props.handleAuthStateChange(AuthState.SignIn, null);
  }

  renderResetFailed () {
    if (!this.state.resetFailed) return;

    return (
      <div className="auth-error errors">
        <p className="body body--small error">
          {this.state.resetFailed}
        </p>
      </div>
    );
  }

  async submitReset () {
    try {
      await Auth.forgotPassword(this.state.email?.toLowerCase());
    } catch (e) {
      console.error(e);
    }
  }

  sendCode () {
    if (!this.state.email || !validator.isEmail(this.state.email)) {
      this.setState({
        loading: false,
        resetFailed: 'A valid email is required'
      });

      return;
    }

    this.submitReset().then(() => {
      this.setState({ codeSent: true });
    }, (e) => {
      this.setState({
        loading: false,
        resetFailed: e
      });
    });
  }

  overrideCode (ev) {
    ev.preventDefault();
    this.setState({
      codeSent: true
    });
  }

  backToCode (ev) {
    ev.preventDefault();
    this.setState({
      codeSent: false
    });
  }

  renderEmail () {
    // if (this.emailStatic) return;

    return (
      <div className="field text-field">
        <label htmlFor="email">Email Address</label>
        <input
          className="input"
          id="email"
          type="email"
          autoComplete="username"
          placeholder="email@address.com"
          required
          onChange={this.handleEmailChange}
        />
        {
          !this.state.codeSent ? (
            <div className="submit-button send-code">
              <a
                href="#"
                className="text-link"
                onClick={this.overrideCode}
              >
                Already have a code?
              </a>
              <TextButton
                filled
                handleClick={this.sendCode}
              >
                Submit
              </TextButton>
            </div>
          ) : ''
        }
      </div>
    );
  }

  renderCode () {
    if (this.codeStatic || !this.state.codeSent) return;

    return (
      <div className="field text-field">
        <label htmlFor="code">Authentication Code</label>
        <input
          className="input"
          id="code"
          type="text"
          autoComplete="one-time-code"
          placeholder="123456"
          required
          onChange={this.handleCodeChange}
        />
      </div>
    );
  }

  renderInstructions () {
    if (this.state.codeSent) {
      return (
        <>
          <h4 className="directions h4">Set your new password.</h4>
          <p className="directions body">
            Enter your password reset code, and set your new password.
          </p>
        </>
      );
    }

    return (
      <>
        <h4 className="directions h4">Request reset code.</h4>
        <p className="directions body">
          Enter the email address for your account.<br />An email with your password reset code
          will then be sent to you.
        </p>
      </>
    );
  }

  render () {
    return (
      <form className="sign-in" onSubmit={this.handleResetPassword}>
        { this.renderInstructions() }
        {this.renderResetFailed()}
        {this.renderEmail()}
        {this.renderCode()}

        {
          this.state.codeSent ? (
            <>
              <div className="field text-field">
                <label htmlFor="password">New password</label>
                <input
                  className="input"
                  id="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="Password"
                  required
                  onChange={this.handlePasswordChange}
                />
              </div>
              <div className="field text-field">
                <label htmlFor="passwordConfirm">Confirm new password</label>
                <input
                  className="input"
                  id="passwordConfirm"
                  type="password"
                  autoComplete="confirm-new-password"
                  placeholder="Password"
                  required
                  onChange={this.handleConfirmPasswordChange}
                />
              </div>
            </>
          ) : ''
        }
        {
          this.state.codeSent ? (
            <div className="submit-button submit-forgot-password">
              <a
                href="#"
                className="text-link"
                onClick={this.backToCode}
              >
                Need a new code?
              </a>
              <TextButton
                formType="submit"
                disabled={this.state.loading}
                filled
              >
                { this.state.loading ? <SpinnerWhite
                  style={{
                    width: '20px',
                    height: '20px'
                  }}
                /> : <span>Submit</span> }
              </TextButton>
            </div>
          ) : ''
        }
        <div className="back-to-login">
          <a
            href="#"
            className="text-link"
            onClick={this.handleBackToLogin}
          >
            Back to Login
          </a>
        </div>
      </form>
    );
  }
}
