import { useState, useCallback } from "react";

export const useAsyncError = () => {
  // eslint-disable-next-line no-unused-vars
  const [ _, setError ] = useState();

  return useCallback((e) => {
    setError(() => {
      console.error(e);
      throw e;
    });
  }, [ setError ]);
};