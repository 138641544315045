import React, { useState } from 'react';
import { Auth } from 'aws-amplify';

export default function ResendVerificationLink () {
  const [ message, setMessage ] = useState(false);

  function handleClick (ev) {
    ev.preventDefault();
    Auth.verifyCurrentUserAttribute('email').then(() => {
      setMessage('Email sent.');
    }, (err) => {
      console.error(err);
    });
  }

  if (message) {
    return (
      <div className="form-link">
        <span className="resend-verification-message">{message}</span>
      </div>
    );
  }

  return (
    <div className="form-link">
      <a className="text-link" href="#" onClick={handleClick}>Resend verification email</a>
    </div>
  );
}
