import React, { useState, useCallback } from 'react';

export const SidebarContext = React.createContext({
  filtersActive: false,
  avatarSidebarActive: false,
  setFiltersActive: () => {},
  setAvatarSidebarActive: () => {}
});

export default function SidebarProvider ({ children }) {
  const [ filtersActive, setFiltersActive ] = useState(false);
  const [ avatarSidebarActive, setAvatarSidebarActive ] = useState(false);

  const contextValue = {
    filtersActive,
    avatarSidebarActive,
    setFiltersActive: useCallback((val) => setFiltersActive(val), []),
    setAvatarSidebarActive: useCallback((val) => setAvatarSidebarActive(val), [])
  };

  return (
    <SidebarContext.Provider value={contextValue}>
      { children }
    </SidebarContext.Provider>
  );
}
