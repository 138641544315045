export class HumanizedError extends Error {
  get humanMessage () {
    return this.message;
  }
}

export class FatalComponentError extends HumanizedError {
}

export class BadAttributeError extends HumanizedError {
}