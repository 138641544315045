'use strict';

export default function multiBackendCreator(createBackends) {
  createBackends = Array.prototype.slice.call(arguments);

  return (manager) => {
    var backends = createBackends.map((createBackend) => {
      return createBackend(manager);
    });

    return {
      setup: () => {
        backends.forEach((backend) => {
          backend.setup();
        });
      },
      teardown: () => {
        backends.forEach((backend) => {
          backend.teardown();
        });
      },
      connectDragSource: (sourceId, node, options) => {
        var disconnects = backends.map((backend) => {
          return backend.connectDragSource(sourceId, node, options);
        });

        return () => {
          disconnects.forEach((disconnect) => {
            disconnect();
          });
        };
      },
      connectDragPreview: (sourceId, node, options) => {
        var disconnects = backends.map((backend) => {
          return backend.connectDragPreview(sourceId, node, options);
        });

        return () => {
          disconnects.forEach((disconnect) => {
            disconnect();
          });
        };
      },
      connectDropTarget: (targetId, node) => {
        var disconnects = backends.map((backend) => {
          return backend.connectDropTarget(targetId, node);
        });

        return () => {
          disconnects.forEach((disconnect) => {
            disconnect();
          });
        };
      },
    };
  };
};