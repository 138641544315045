import React, { useState, useCallback } from 'react';

export const ProfileImageContext = React.createContext({
  profileImage: null,
  setProfileImage: () => {}
});

export default function ProfileImageProvider ({ children }) {
  const [ profileImage, setProfileImage ] = useState(null);

  const contextValue = {
    profileImage,
    setProfileImage: useCallback((val) => setProfileImage(val), [])
  };

  return (
    <ProfileImageContext.Provider value={contextValue}>
      {children}
    </ProfileImageContext.Provider>
  );
}
