import { useContext } from 'react';
import { ProfileImageContext } from './profile-image-provider';

export const useProfileImage = () => {
  const {
    profileImage,
    setProfileImage
  } = useContext(ProfileImageContext);

  return { profileImage, setProfileImage };
};
