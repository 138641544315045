import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
import { SpinnerWhite } from '../Icons';
import { TextButton } from '../Button';

const NewPassword = ({
  user,
  handleAuthStateChange
}) => {
  const [ loading, setLoading ] = useState(false);
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ failed, setFailed ] = useState('');

  const handlePasswordChange = (ev) => {
    setPassword(ev.target.value);
  };

  const handleConfirmPasswordChange = (ev) => {
    setConfirmPassword(ev.target.value);
  };

  const completeNewPassword = async () => {
    var newUser, nextAuthState;

    try {
      newUser = await Auth.completeNewPassword(user, password);

      console.debug(newUser);

      if (newUser.challengeName) {
        throw new Error(`Challenge not supported! ${user.challengeName}`);
      }

      nextAuthState = [ AuthState.SignedIn, user ];
    } catch (e) {
      console.error(`Error setting new password: ${e.stack ? e.stack : JSON.stringify(e)}`);

      if (e.code === 'InvalidParameterException') {
        return () => {
          setLoading(false);
          setFailed('Password must be at least 8 characters and include letters and numbers');
        };
      }

      if (e.code === 'NotAuthorizedException') {
        return () => {
          setLoading(false);
          setFailed(e.message);
        };
      }

      throw e;
    }

    if (nextAuthState) {
      handleAuthStateChange(...nextAuthState);
    }
  };

  const renderFailed = () => {
    if (!failed) return;

    return (
      <div className="auth-error">
        <p className="body body--small error">{failed}</p>
      </div>
    );
  };

  const handleCompleteNewPassword = (ev) => {
    ev.preventDefault();

    if (password !== confirmPassword) {
      setFailed('Passwords must match');

      return;
    }

    setLoading(true);

    completeNewPassword().then(() => {
      console.info('New password set');
    }, err => {
      console.error(`Error setting new password in: ${err.stack ? err.stack : JSON.stringify(err)}`);
      setLoading(false);
    });
  };

  const handleBackToLogin = (ev) => {
    ev.preventDefault();
    handleAuthStateChange(AuthState.SignIn, null);
  };

  return (
    <form className="sign-in" onSubmit={handleCompleteNewPassword}>
      <h4 className="directions h4">Set your new password.</h4>
      <p className="directions body">Your new password must have at least 8 characters and include at
      least one number, one upper-case letter, and one lower-case letter.</p>

      {renderFailed()}

      <div className="field text-field">
        <label htmlFor="password">New Password</label>
        <input
          className="input"
          id="password"
          type="password"
          autoComplete="new-password"
          placeholder="New password"
          required
          onChange={handlePasswordChange}
        />
      </div>

      <div className="field text-field">
        <label htmlFor="confirmPassword">Confirm New Password</label>
        <input
          className="input"
          id="confirmPassword"
          type="password"
          autoComplete="confirm-new-password"
          placeholder="New password"
          required
          onChange={handleConfirmPasswordChange}
        />
      </div>

      <div className="submit-button new-password">
        <TextButton
          formType="submit"
          disabled={loading}
          filled
        >
          { loading ? <SpinnerWhite
            style={{
              width: '20px',
              height: '20px'
            }}
          /> : <span>Submit</span> }
        </TextButton>
      </div>

      <div className="back-to-login">
        <a
          href="#"
          className="text-link"
          onClick={handleBackToLogin}
        >
          Back to Login
        </a>
      </div>
    </form>
  );
};

export default NewPassword;
