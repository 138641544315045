import React, { useState, useCallback } from 'react';

export const PreviewContext = React.createContext({
  previewActive: false,
  setPreviewActive: () => {}
});

export default function PreviewProvider ({ children }) {
  const [ previewActive, setPreviewActive ] = useState(false);

  const contextValue = {
    previewActive,
    setPreviewActive: useCallback((val) => setPreviewActive(val), [])
  };

  return (
    <PreviewContext.Provider value={contextValue}>
      { children }
    </PreviewContext.Provider>
  );
}
